/*
Bootstrap customization for Agency theme
By Orchard Core Team
 */
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

@import "modules/_flow.scss";
@import "modules/_messages.scss";
@import "modules/_widgets.scss";
@import "modules/_pager.scss";

#togglePassword {
    width: 44px;
    padding: 0;
}
