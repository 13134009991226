// Messages
// ------------------------------
.message {
    position: relative;
    padding: $alert-padding-y $alert-padding-x;
    margin-bottom: $alert-margin-bottom;
    border: $alert-border-width solid transparent;
    @include border-radius($alert-border-radius);
  }

@each $color, $value in $theme-colors {
    .message-#{$color} {
        @include alert-variant(shift-color($value, $alert-bg-scale), shift-color($value, $alert-border-scale), shift-color($value, $alert-color-scale));
    }
  }
